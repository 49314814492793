import React, { useState, useEffect } from 'react';
import { $ } from 'react-jquery-plugin';
import EducatorsMainHeader from '../../../commonComponents/EducatorsMainHeader/EducatorsMainHeader';
import HeadOfficeMainHeader from '../../../commonComponents/HeadOfficeMainHeader/HeadOfficeMainHeader';
// eslint-disable-next-line
import { getUpcomingVisits, getUpcomingVisiter, getAllEducators, getUpcomingVisitsNew } from '../../../functions/api';
import VisitsList from '../../../commonComponents/CommonVisitsListComponent/VisitsList';
import { useSelector } from "react-redux"
import { Link } from 'react-router-dom';
import './styles.css';
import EmptyData from '../../../commonComponents/EmptyData/EmptyData';
import LoaderComponent from '../../../commonComponents/LoaderComponent/LoaderComponent'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SortIcon from '../../../images/sort.png';
// import moment from 'moment';

const VisitsPage = (props) => {

  const store = useSelector(state => state.auth)
  const [schedule, setSchedule] = useState([]);
  const [userRole, setUserRole] = useState(1)
  const [noData, setNoData] = useState(false)
  const [showLoader, setShowLoader] = useState(true)
  const [taskStatus, setTaskStatus] = useState('all')
  // const [showAssignedTo, setShowAssignedTo] = useState(false)
  // const [educatorsData, setEducatorsData] = useState([])
  // const [selectedEducatorName, setSelectedEducatorName] = useState('');
  const [sort, set_sort] = useState(false);
  const [atozSort, setAtoZSort] = useState('AtoZ')
  const [totalPages, setTotalPages] = useState(0)
  const [activePages, setActivePages] = useState(0)
  const [showUpcomingMonth, setShowUpcomingMonth] = useState(false)
  const [searchTxt, setSearchTxt] = useState('');
  const [showBtn, setShowBtn] = useState(false)


  useEffect(() => {

    $(function () {
      $('.mdc-text-field--outlined .mdc-text-field__input,.material-textfield textarea').on('focusout', function () {
        if ($(this).val().length > 0) {
          $(this).addClass('active');
        } else {
          $(this).removeClass('active');
        }
      })
    })

  }, [])

  useEffect(() => {
    window.localStorage.removeItem('folderParentId');
    getAllInitalData()
    // eslint-disable-next-line
  }, [store.userRole])

  async function getAllInitalData(index, searchVal, showUpcomingMonth, sortby) {
    setShowBtn(false)
    setUserRole(store.userRole)
    // if (store.userRole === 1) {
    // let scheduleArray = await getUpcomingVisits()
    let pageCount = index ? index : 0
    let search = searchVal ? searchVal : ''
    setSchedule([])
    let sort = sortby ? sortby : atozSort === 'AtoZ' ? 'ASC' : 'DESC'
    let scheduleArray = await getUpcomingVisitsNew(pageCount, search, 0, sort, showUpcomingMonth)
    if (scheduleArray.data !== undefined && scheduleArray.data !== '' && scheduleArray.data.data.rows.length > 0) {
      setNoData(false)
      setShowLoader(false)
      setTotalPages(scheduleArray.data.data.pager.total_pages)
      setActivePages(pageCount + 1)
      setSchedule(scheduleArray.data.data.rows)
      setTimeout(() => {
        setShowBtn(true)
      }, 5000);
    } else {
      setShowLoader(false)
      setSchedule([])
      setNoData(true)
    }
  }

  const filterVisits = async (e, value) => {
    e.preventDefault()
    setTaskStatus(value)

    if (value === "all") {
      setShowLoader(true)
      setShowUpcomingMonth(false)
      await getAllInitalData()
    }
    else if (value === "personal") {
      setShowUpcomingMonth(false)
    }
    else if (value === 'upcoming') {
      setShowUpcomingMonth(true)
      setShowLoader(true)
      await getAllInitalData(0, '', true)
    }
  }

  const SortByAtoZ = (e, val) => {
    if (val !== atozSort) {
      setAtoZSort(val)
      let sort = val === 'AtoZ' ? 'ASC' : 'DESC'
      if (showUpcomingMonth) {

        getAllInitalData(0, '', true, sort)
      } else {
        getAllInitalData(0, '', false, sort)
      }

    }
  }

  const Pagination = ({ pageCount, activeIndex, onPageClick, onNextClick, onPrevClick }) => {
    const handleNavigationClick = (index) => {
      if (index >= 1 && index <= pageCount) {
        onPageClick(index);
      }
    };

    const handleNextClick = () => {
      const nextIndex = Math.min(activeIndex + 1, pageCount);
      onNextClick(nextIndex);
    };

    const handlePrevClick = () => {
      const prevIndex = Math.max(activeIndex - 1, 1);
      onPrevClick(prevIndex);
    };

    const generatePagination = () => {
      const paginationItems = [];
      for (let i = 1; i <= pageCount; i++) {
        paginationItems.push(
          <li
            key={i}
            className={`sc-gEvEer buypnQ pagination-item ${activeIndex === i ? 'active' : ''}`}
            onClick={() => handleNavigationClick(i)}
          >
            {i}
          </li>
        );
      }
      return paginationItems;
    };

    return (
      <ul className="sc-aXZVg LQiaC pagination">
        <li className="sc-gEvEer buypnQ prev pagination-item" onClick={handlePrevClick}>
          〈
        </li>
        {generatePagination()}
        <li className="sc-gEvEer buypnQ next pagination-item" onClick={handleNextClick}>
          〉
        </li>
      </ul>
    );
  };


  const handlePageClick = (pageIndex) => {
    if (activePages !== pageIndex) {
      if (showUpcomingMonth) {
        getAllInitalData(pageIndex - 1, '', true)
      } else {
        getAllInitalData(pageIndex - 1)
      }
      setActivePages(pageIndex)
    }
  };

  const handleNextClick = (pageIndex) => {
    if (activePages !== pageIndex) {
      if (showUpcomingMonth) {
        getAllInitalData(pageIndex - 1, '', true)
      } else {
        getAllInitalData(pageIndex - 1)
      }
      setActivePages(pageIndex)
    }
  };

  const handlePrevClick = (pageIndex) => {
    if (activePages !== pageIndex) {
      if (showUpcomingMonth) {
        getAllInitalData(pageIndex - 1, '', true)
      } else {
        getAllInitalData(pageIndex - 1)
      }
      setActivePages(pageIndex)
    }
  };

  const handleCloseClick = async () => {
    setSearchTxt('')
    getAllInitalData(0)
  }

  return (

    <>
      {
        userRole === 1 || userRole === 3 ?
          <EducatorsMainHeader />
          :
          <HeadOfficeMainHeader />
      }
      <LoaderComponent displayLoader={showLoader} />
      <div className="main-wrapper mtb-70">
        <div className="container">
          <div className="dashboard-wrap">
            <div className="row">
              <div className="col-md-12">
                <div className="task-listing-block m-n-s-block">
                  <div className="common-header-row">
                    <h4>Upcoming M&S
                      {userRole === 2 ? <Link to={`/add-new-m&s`} class="mdc-button mdc-button--raised green-btn"><span class="mdc-button__label"> Add New M&S </span> </Link> : null}</h4>
                    <div className='sorting-block'>
                      {
                        userRole === 2 ?

                          <>
                            <button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' onClick={(e) => getAllInitalData(0, searchTxt)}  >
                              <span className="mdc-button__label">Search</span>
                            </button>
                            <div className="search-block-content">
                              <label className="mdc-text-field mdc-text-field--outlined">
                                <input onChange={(e) => setSearchTxt(e.target.value)} value={searchTxt} type="text" className="mdc-text-field__input" aria-labelledby="my-label-id" />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">Search By Educator Name</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                </span>
                                {
                                  searchTxt ?
                                    <span className="material-icons close" onClick={handleCloseClick}>close</span>
                                    : null
                                }
                              </label>
                            </div>
                          </>
                          :
                          null
                      }
                      {userRole === 1 || userRole === 3 ?
                        sort ?
                          <div>
                            <span> Sort According to Visit date </span>
                            <img onClick={(e) => [atozSort === 'ZtoA' ? SortByAtoZ(e, 'AtoZ') : SortByAtoZ(e, 'ZtoA'), set_sort(!sort)]} style={{ height: '30px', width: '30px', transform: 'rotate(180deg)' }} src={SortIcon} alt="" />
                          </div>
                          :
                          <div>
                            <span> Sort According to Visit date </span>
                            <img onClick={(e) => [atozSort === 'AtoZ' ? SortByAtoZ(e, 'ZtoA') : SortByAtoZ(e, 'AtoZ'), set_sort(!sort)]} style={{ height: '30px', width: '30px' }} src={SortIcon} alt="" />
                          </div>
                        : ""}
                      {
                        <div>
                          <span>  Sort By : </span>
                          <Select labelId="demo-simple-select-helper-label" id="demo-simple-select-helper"
                            value={atozSort}>
                            <MenuItem value="AtoZ" onClick={(e) => SortByAtoZ(e, 'AtoZ')}>A to Z</MenuItem>
                            <MenuItem value="ZtoA" onClick={(e) => SortByAtoZ(e, 'ZtoA')}>Z to A</MenuItem>
                          </Select>
                        </div>
                      }
                      {
                        userRole === 2 ?
                          <Select labelId="demo-simple-select-helper-label" id="demo-simple-select-helper"
                            value={taskStatus}>
                            <MenuItem value="all" onClick={(e) => filterVisits(e, "all")}>All</MenuItem>
                            <MenuItem value="upcoming" onClick={(e) => filterVisits(e, "upcoming")}>Upcoming (By Month)</MenuItem>
                          </Select>
                          :
                          null
                      }
                    </div>
                  </div>

                  {
                    noData === true ? <EmptyData title="Your Mentoring & Support List is empty!" desc={userRole === 1 ? 'M&S assigned by your Co ordination Unit will be displayed here!' : 'Click Add New to create and assign new M&S to Educators.'} /> :
                      <VisitsList data={schedule} />
                  }
                  {showBtn ?
                    <div>
                      <Pagination
                        pageCount={totalPages}
                        activeIndex={activePages}
                        onPageClick={handlePageClick}
                        onNextClick={handleNextClick}
                        onPrevClick={handlePrevClick} />
                    </div>
                    : null
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VisitsPage;